<template>
  <div class="reassurance">
    <ul>
      <li>
        <i class="icon-packaging"></i>
        <div class="title">{{ $t('Packaging') }}</div>
        <p>{{ $t('Opt for an online-exclusive and more environmentally friendly packaging option') }}</p>
      </li>
      <li>
        <i class="icon-warranty-card"></i>
        <div class="title">{{ $t('Extended warranty') }}</div>
        <p>{{ $t('Benefit from an additional 3rd year of warranty and complimentary first service') }}</p>
      </li>
      <li>
        <i class="icon-service"></i>
        <div class="title">{{ $t('Concierge service') }}</div>
        <p>{{ $t('Shop with a personalized experience provided by our Concierge team') }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Reassurance'
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

.reassurance {
  border-top: 1px solid rgba(#979797, 0.3);

    ul {
    display: flex;
    margin: 0 auto;
    padding: 6rem 4rem;
    max-width: 130rem;
    list-style: none;
    justify-content: space-between;
    @include bp(max lg) {
      flex-direction: column;
      padding: 6rem 4rem;
    }
    @include bp(max md) {
      padding: 4rem 2rem;
    }
  }

  li {
    width: 30%;
    position: relative;
    padding: 0 0 0 8rem;

    @include bp(max lg) {
      width: 100%;
      margin-bottom: 4rem;
      padding: 0 0 0 5rem;
    }
    @include bp(max md) {
      margin-bottom: 2rem;
    }

    .title {
      font-size: em(16);
      font-weight: 500;
      letter-spacing: em(1.85, 16);
      line-height: em(24, 16);
      text-transform: uppercase;
      margin-bottom: 1rem;
      color: var(--zenith-theme-primary);

      @include bp(max lg) {
        margin-bottom: 0;
      }
    }

    p {
      color: #7A7A7A;
      font-size: em(15);
      line-height: em(28, 15);
      margin-bottom: 0;
    }

    i {
      position: absolute;
      left: 0;
      top: 0;
      font-size: em(55);
      color: var(--zenith-theme-primary);

      @include bp(max lg) {
        font-size: em(35);
      }
    }
  }
}
</style>
